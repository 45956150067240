// @ts-nocheck

import * as React from 'react';
import {useLoaderData, useLocation} from "react-router-dom";
import {ICertificateOfAuthenticity} from "../types";
import {NFT_PROVIDER, WEAVEDB_CONTRACT_TXID} from "../consts";
import {getCollectionNameFromAddress} from "../utils/collections";
import dayjs from "dayjs";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


// @ts-ignore
import SDK from "weavedb-sdk";
import {Chain, OpenSeaSDK} from "opensea-js";
import {TokenStandard} from "opensea-js/lib/types";
import {Helmet} from "react-helmet";
import {useEffect} from "react";
import COAImage from "./coa-image";


export async function loader({params} : {params: any}) {

    let db = await new SDK({
        contractTxId: WEAVEDB_CONTRACT_TXID,
        remoteStateSyncEnabled: true,
        remoteStateSyncSource: "https://dre-3.warp.cc/contract",
    })
    await db.initializeWithoutWallet();
    let coaData = await db.cget(
        "coas_nftcanvases",
        ["hash", "==", params.coaHash]
    );

    console.log(coaData);
    try {
        let certificateOfAuthenticity = coaData.at(-1).data;
        console.log(certificateOfAuthenticity);

        let openseaSDK = new OpenSeaSDK(NFT_PROVIDER, {
            chain: Chain.Mainnet,
            apiKey: "36b5db9c2de144639ebc1e2a2a09573d",
        });

        const asset = {
            tokenAddress: certificateOfAuthenticity.collectionAddress,
            tokenId: certificateOfAuthenticity.tokenId,
            tokenStandard: TokenStandard.ERC721,
        };

        let accountAddress = certificateOfAuthenticity.ownerWallet;
        console.log(`COA Owner Wallet = ${accountAddress}`)
        console.log(`COA Printer Wallet = ${certificateOfAuthenticity.printerWallet}`)

        const balance = await openseaSDK.getBalance({
            accountAddress,
            asset,
        });
        console.log(`Wallet ${accountAddress} balance for token ${certificateOfAuthenticity.tokenId} = ${balance}`)

        const isAuthentic = balance.gt(0);

        let imageURI = certificateOfAuthenticity.image;
        imageURI = imageURI.replace(/\\(.)/mg, "$1");
        // if (imageURI.includes("ipfs://")) {
        //     imageURI = imageURI.replace("ipfs://", "https://ipfs.io/ipfs/");
        // }

        let returnCOA: ICertificateOfAuthenticity = {
            coaHash: certificateOfAuthenticity.coaHash,
            collectionAddress: certificateOfAuthenticity.collectionAddress,
            collectionName: await getCollectionNameFromAddress(certificateOfAuthenticity.collectionAddress, NFT_PROVIDER),
            extraNotes: "n/a",
            imageURI: imageURI,
            ownerWallet: certificateOfAuthenticity.ownerWallet,
            printMaterial: certificateOfAuthenticity.printMaterial,
            printSite: certificateOfAuthenticity.printSite,
            printSize: certificateOfAuthenticity.printSize,
            printTimestamp: dayjs.unix(certificateOfAuthenticity.timestamp).format("lll"),
            printerWallet: certificateOfAuthenticity.printerWallet,
            tokenID: certificateOfAuthenticity.tokenId,
            authentic: isAuthentic,
        }
        return returnCOA;
    } catch (e) {
        console.error(e);
        throw Error("There was a problem retrieving the requested certificated.");
    }
}

export default function COAPage() {
    const coa = useLoaderData() as ICertificateOfAuthenticity;

    return (
        <>
            <Helmet>
                <title>{`UNFORGED.IO - ${coa.collectionName} #${coa.tokenID}`}</title>
                <meta name='description' content={`Certificate Of Authenticity for ${coa.collectionName} #${coa.tokenID}`} />
            </Helmet>
            <div id="header-banner">
                <div className="banner-content single-page text-center headerBanner">
                    {/*<img className="width100" src={coa.imageURI} />*/}
                    <MobileView>
                        <COAImage image_uri={coa.imageURI} />
                    </MobileView>
                </div>
            </div>
            <section id="project">
                <div className="row">
                    <BrowserView>
                        This is only available on mobile devices.
                    </BrowserView>
                    <MobileView>
                        <div className="col-sm-5 project-sidebar w-bg" style={{padding: "20px"}}>
                            <div className="section-description light ">
                                <h4>{coa.collectionName} #{coa.tokenID}</h4>
                                <div className="project-info">
                                    <div className="info width100">
                                        <p className={"noPadLeft"}><span
                                            className={"infoLabel"}>Authentic:</span><span
                                            className={"width50"}>{(coa.authentic ? "Yes" : "No").padStart(12, "\u00A0")}</span>
                                        </p>
                                    </div>
                                    <div className="info width100">
                                        <p><span className={"infoLabel"}>Size:</span><span
                                            className={"width50"}>{coa.printSize.padStart(24, "\u00A0")}</span></p>
                                    </div>
                                    <div className="info width100">
                                        <p><span className={"infoLabel"}>Material:</span><span
                                            className={"width50"}>{coa.printMaterial.padStart(18, "\u00A0")}</span>
                                        </p>
                                    </div>
                                    <div className="info width100">
                                        <p><span className={"infoLabel"}>Print Date:</span><span
                                            className={"width50"}>{coa.printTimestamp.toString().padStart(28, "\u00A0")}</span>
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </MobileView>
                </div>
            </section>
        </>
    );
}