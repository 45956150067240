import {useRouteError} from "react-router-dom";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Typography from "@mui/material/Typography";
import ConnectButton from "./components/connect-button";
import DisconnectButton from "./components/disconnect-button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useWeb3React} from "@web3-react/core";


const defaultTheme = createTheme();


export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    const { isActive} = useWeb3React();

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <MuiAppBar position="absolute">
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <VerifiedUserIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            BlockchainCOA
                        </Typography>
                        {!isActive ? <ConnectButton/> : <DisconnectButton/>}
                    </Toolbar>
                </MuiAppBar>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                        <Grid
                            container
                            spacing={0}
                            direction={"column"}
                            alignItems={"center"}
                        >
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {(error as { statusText?: string })?.statusText || (error as Error)?.message}
                            </Alert>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}