import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {router} from "./router";

import {Web3ReactHooks, Web3ReactProvider} from '@web3-react/core'
import type {MetaMask} from '@web3-react/metamask'

import {hooks as metaMaskHooks, metaMask} from "./connectors/metaMask"
import {RouterProvider} from "react-router-dom";

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/style.css";

const connectors: [MetaMask, Web3ReactHooks][] = [
    [metaMask, metaMaskHooks],
]

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Web3ReactProvider connectors={connectors}>
                <RouterProvider router={router}/>
            </Web3ReactProvider>
        </LocalizationProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
