import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {CardActionArea, SelectChangeEvent} from '@mui/material';
import {Helmet} from "react-helmet";
import { BrowserView, MobileView } from 'react-device-detect';
import {Button, Form, ListGroup } from 'react-bootstrap';
import {ChangeEvent} from "react";
import {WEAVEDB_CONTRACT_TXID} from "../consts";
// @ts-ignore
import SDK from "weavedb-sdk";
import { Link } from 'react-router-dom';


export default function SearchPage() {

    const [searchType, setSearchType] = React.useState<string>("hash");
    const [searchValue, setSearchValue] = React.useState<string | null>(null);
    const [searchTokenID, setSearchTokenId] = React.useState<string | null>(null);
    const [searchResults, setSearchResults] = React.useState<Array<any> | null>(null);
    function onSearchTypeChange(event: ChangeEvent<HTMLSelectElement>) {
        setSearchType(event.target.value);
    }

    async function onSearchClick() {
        if (searchValue === null || searchValue.length === 0) {
            alert("You must enter a search value first.");
            return;
        }
        let db = await new SDK({
            contractTxId: WEAVEDB_CONTRACT_TXID,
            remoteStateSyncEnabled: true,
            remoteStateSyncSource: "https://dre-3.warp.cc/contract",
        })
        await db.initializeWithoutWallet();
        let coaData = await db.cget(
            "coas_nftcanvases",
            [searchType, "==", searchValue]
        );
        console.log(coaData);
        if (searchTokenID === null || searchTokenID.length === 0){
            setSearchResults(coaData);
        } else if (searchTokenID.length > 0){
            setSearchResults(coaData.filter((coa: { data: { tokenId: number; }; }) => coa.data.tokenId === parseInt(searchTokenID)));
        }
    }

    function onSearchValueChange(event: ChangeEvent<HTMLInputElement>) {
        setSearchValue(event.target.value);
    }

    function onSearchTokenIDChange(event: ChangeEvent<HTMLInputElement>) {
        setSearchTokenId(event.target.value);
    }

    function SearchResultArea() {
        if (searchResults?.length === 0){
            return <p>Your search did not return any results.</p>
        }
        return (
            <ListGroup>
                {searchResults?.map(result => {
                    return (
                        <Link to={`/auth/${result.data.hash}`} target={"_blank"}>
                            <ListGroup.Item key={result.data.hash}>
                                {searchType !== "collectionAddress" ? <p>Collection Address: {result.data.collectionAddress}</p> : null}
                                <p>Token ID: {result.data.tokenId}</p>
                            </ListGroup.Item>
                        </Link>
                    );
                })}
            </ListGroup>
        );
    }

    return (
        <>
            <Helmet>
                <title>{`UNFORGED.IO - Search`}</title>
                <meta name='description' content={`UNFORGED.IO - Search`} />
            </Helmet>
            <section id="project">
                <div className="row">
                    <BrowserView>
                        This is only available on mobile devices.
                    </BrowserView>
                    <MobileView>
                        <div className="col-sm-5 project-sidebar w-bg" style={{padding: "20px"}}>
                            <Form>
                                <Form.Group className="mb-3" controlId="formSearchType">
                                    <Form.Label>Search Type</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={onSearchTypeChange}>
                                        <option value="hash">Hash</option>
                                        <option value="collectionAddress">Collection Address</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formSearchValue">
                                    <Form.Label>{searchType === "hash" ? <>Hash</> : <>Collection Address</>}</Form.Label>
                                    <Form.Control
                                        aria-label={"Search Value"}
                                        type="text"
                                        onChange={onSearchValueChange}
                                    />
                                </Form.Group>
                                {searchType === 'collectionAddress' ?
                                    <Form.Group className="mb-3" controlId="formTokenId">
                                        <Form.Label>Token ID (optional)</Form.Label>
                                        <Form.Control
                                            aria-label={"Token ID"}
                                            type="text"
                                            onChange={onSearchTokenIDChange}
                                        />
                                    </Form.Group> : null
                                }
                                <Button variant="outline-primary" type="button" onClick={onSearchClick}>Search</Button>
                            </Form>
                            <hr/>
                            { searchResults === null ? <p>Search results will appear here.</p> : <SearchResultArea />}
                        </div>
                    </MobileView>
                </div>
            </section>
        </>
    );
}