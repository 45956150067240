import {Outlet} from "react-router-dom";

import {faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import COAImage from "../components/coa-image";
import * as React from "react";


export default function Root() {

    return (
        <>
            <style>
                {`
                    .footerStyle {
                        padding:20px;
                    }
                    
                    .headerBanner {
                        padding: 0px;
                        border-top: 3px solid #000;
                        border-bottom: 3px solid #000;
                    }
                    
                    .width50 {
                        width: 50%;
                    }
                    
                    .width100 {
                        width: 100%;
                    }
                    
                    .infoLabel {
                        width:50%;
                        font-weight:bold;
                    }
                    
                    .noPadLeft {
                        padding-left: 0px;
                    }
                `}
            </style>
            <header>
                <div className="tp-header-area" style={{borderBottom: "solid", borderColor: "black"}}>
                    <div className="container noPadLeft">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                                <div className="tp-logo">
                                    <a href="index.html">UNFORGED.IO</a>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-9 col-6">
                                <div className="tp-menu-bar text-end d-md-none">
                                    <button><i className="fa fa-bars" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="body-overlay"></div>
            </header>
            <div id="page-content" className="container" style={{padding: "0px"}}>
                <Outlet/>
            </div>
            <footer className="footerStyle">
                <div className="container">
                    <p className="footer-info">© UNFORGED.IO
                        <span className="social pull-right">
                            <a href="https://twitter.com/canvasesnft" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                            &nbsp;
                            &nbsp;
                            <a href="https://instagram.com/nft.canvases" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                        </span>
                    </p>
                </div>
            </footer>
        </>
    );
}