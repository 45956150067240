import {createHashRouter,} from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import WelcomePage from "./components/welcome-page";
import SearchPage from "./components/search-page";
import COAPage, {loader as coaLoader} from "./components/coa-page";
import CreateCOAPage, {loader as createCoaLoader} from "./components/create-page";

export const router = createHashRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                element: <WelcomePage/>,
            },
            {
                path: "auth/:coaHash",
                element: <COAPage/>,
                loader: coaLoader,
            },
            {
                path: "create/",
                element: <CreateCOAPage/>,
                loader: createCoaLoader,
            },
            {
                path: "search/",
                element: <SearchPage/>,
            },
        ],
    },
]);