import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {CardActionArea} from '@mui/material';
import {Helmet} from "react-helmet";
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';


export default function WelcomePage() {
    return (
        <>
            <Helmet>
                <title>{`UNFORGED.IO - Home`}</title>
                <meta name='description' content={`Homepage for UNFORGED.IO`} />
            </Helmet>
            <section id="project">
                <div className="row">
                    <BrowserView>
                        This is only available on mobile devices.
                    </BrowserView>
                    <MobileView>
                        <div className="col-sm-5 project-sidebar w-bg" style={{padding: "20px"}}>
                            <div className="section-description light ">
                                <p>UNFORGED.IO is the #1 technology leading the authentication space.</p>
                                <p>Scan the tag on your print to view your Certificate of Authenticity.</p>
                                <p><Link to={"search"}>Click here to search existing COAs.</Link></p>
                            </div>
                        </div>
                    </MobileView>
                </div>
            </section>
        </>
    );
}