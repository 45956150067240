import {useWeb3React} from "@web3-react/core";
import {WalletButton} from "./wallet-button";

export default function DisconnectButton() {
    const {connector, account} = useWeb3React();

    // let accountString = account?.slice(0, 4);
    return (
        <WalletButton variant="contained" onClick={() => {
            if (connector?.deactivate) {
                void connector.deactivate()
            } else {
                void connector.resetState()
            }
        }}>Disconnect {account!.slice(0, 4) + "..." + account!.slice(-4)}</WalletButton>
    );
}