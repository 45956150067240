import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import COAImage from "./coa-image";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import * as React from "react";
import {ICertificateOfAuthenticity} from "../types";
import dayjs from "dayjs";

export default function COACard({coa}: { coa: ICertificateOfAuthenticity }) {
    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid container item spacing={1} direction={"column"} alignItems={"center"}>
                        <COAImage image_uri={coa.imageURI}/>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography variant={"h6"}>Token Info</Typography>
                        <Typography variant={"subtitle1"}>{coa.collectionName} #{coa.tokenID}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Tooltip title={"Was this printed by its owner?"}>
                            <Typography variant={"h6"}>Authentic</Typography>
                        </Tooltip>
                        <Typography
                            variant={"subtitle1"}>{coa.ownerWallet == coa.printerWallet ? "Yes" : "No"}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography variant={"h6"}>Print Size</Typography>
                        <Typography variant={"subtitle1"}>{coa.printSize}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography variant={"h6"}>Print Material</Typography>
                        <Typography variant={"subtitle1"}>{coa.printMaterial}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography variant={"h6"}>Print Timestamp</Typography>
                        <Typography variant={"subtitle1"}>{ coa.printTimestamp }</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography variant={"h6"}>Print Site</Typography>
                        <Typography variant={"subtitle1"}>{coa.printSite}</Typography>
                    </Grid>
                    {coa.extraNotes.length > 0 ?
                        <>
                            <Grid item xs={12}>
                                <Typography variant={"h6"}>Extra Notes</Typography>
                                <Typography variant={"subtitle1"}>{coa.extraNotes}</Typography>
                            </Grid>
                        </> : null}
                </Grid>
            </CardContent>
        </Card>
    );
}