import * as React from 'react';
import {ChangeEvent} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {COACreatePageData, SelectOption, ICertificateOfAuthenticity, ICreateCertificateOfAuthenticity} from "../types";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {Contract, ethers} from "ethers";
import {useWeb3React} from "@web3-react/core";
import {BlockchainCOA_ABI} from "../abi/blockchaincoa-abi";
import {BLOCKCHAIN_COA_CONTRACT_ADDRESS, CREATOR_WALLET_ADDRESSES, NFT_PROVIDER} from "../consts";
import ConnectButton from "./connect-button";
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Select,
    Stack
} from "@mui/material";
import {ERC721_ABI} from "../abi/erc721-abi";
import COACard from "./coa-card";
import {ErrorAlert} from "./alert";
import dayjs, {Dayjs} from 'dayjs';
import { getCollectionNameFromAddress } from '../utils/collections';
import {useLoaderData} from "react-router-dom";
import {generateCOAHash} from "../utils/coa-hashes";
import {isValidHttpUrl} from "../utils/generic";


// @ts-ignore
export async function loader({params}) {

    const provider = new ethers.providers.Web3Provider(window.ethereum!)

    const creatorWalletResponse = await fetch(`${process.env.REACT_APP_TOOLS_API_HOST}/system/creator_wallets`);
    const creator_wallets = await creatorWalletResponse.json();
    console.debug(creator_wallets);

    let createPageData: COACreatePageData = {
        blockchainCOAOwner: creator_wallets,
        printSizes: [
            {id: 1, label: "12x12"},
            {id: 2, label: "18x18"},
            {id: 3, label: "24x24"},
            {id: 4, label: "30x30"},
            {id: 5, label: "36x36"},
            {id: 6, label: "13x24"},
            {id: 7, label: "40x40"},
            {id: 8, label: "44x44"},
        ],
        printMaterials: [
            {id: 1, label: "Canvas"},
            {id: 2, label: "Acrylic"},
            {id: 3, label: "Photogloss"},
            {id: 4, label: "Canvas x SAINT HENRI"},
            {id: 5, label: "Bulletproof"},
        ],
        printSites: [
            {id: 1, label: "nftcanvases.com"},
        ],
    }
    console.debug(createPageData);
    return createPageData;
}


export default function CreateCOAPage() {
    const { account, isActive} = useWeb3React();

    const createPageData = useLoaderData() as COACreatePageData;

    const provider = new ethers.providers.Web3Provider(window.ethereum!);
    const signer = provider.getSigner(account);

    const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
    const [previewCOA, setPreviewCOA] = React.useState<ICertificateOfAuthenticity | null>(null);
    const [coaRegisterTXHash, setCoaRegisterTxHash] = React.useState<string | null>(null);

    const [nftContract, setNftContract] = React.useState<Contract | null>(null);

    const [collectionAddress, setCollectionAddress] = React.useState<string>("");
    const [collectionAddressError, setCollectionAddressError] = React.useState<boolean>(false);

    const [collectionName, setCollectionName] = React.useState<string>("");
    const [collectionNameError, setCollectionNameError] = React.useState<boolean>(false);

    const [tokenID, setTokenID] = React.useState<string>("");
    const [tokenIdError, setTokenIdError] = React.useState<boolean>(false);

    const [imageURI, setImageUri] = React.useState<string>("");
    const [imageURIError, setImageUriError] = React.useState<boolean>(false);

    const [printSize, setPrintSize] = React.useState<SelectOption | string | null>(createPageData.printSizes[0]);
    const [printSizeError, setPrintSizeError] = React.useState<boolean>(false);

    const [printMaterial, setPrintMaterial] = React.useState<SelectOption | string | null>(createPageData.printMaterials[0]);
    const [printMaterialError, setPrintMaterialError] = React.useState<boolean>(false);

    const [ownerWallet, setOwnerWallet] = React.useState<string>("");
    const [ownerWalletError, setOwnerWalletError] = React.useState<boolean>(false);

    const [printerWallet, setPrinterWallet] = React.useState<string>("");
    const [printerWalletError, setPrinterWalletError] = React.useState<boolean>(false);

    const [printTimestamp, setPrintTimestamp] = React.useState<Dayjs>(dayjs());

    const [printSite, setPrintSite] = React.useState<SelectOption | string | null>(createPageData.printSites[0]);
    const [printSiteError, setPrintSiteError] = React.useState<boolean>(false);

    const [extraNotes, setExtraNotes] = React.useState<string>("");

    const [coaHash, setCoaHash] = React.useState<string>("");
    const [coaHashError, setCoaHashError] = React.useState<boolean>(false);

    async function onCollectionAddressChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        let value = event.target.value;
        let isValidCollectionAddress = ethers.utils.isAddress(value);
        setCollectionAddressError(!isValidCollectionAddress);
        setPreviewCOA(null);
        setNftContract(null);
        setCollectionAddress(value);
        setCollectionName("");
        setCollectionNameError(false)
        setTokenID("");
        setTokenIdError(false)
        setImageUri("");
        setImageUriError(false);
        setOwnerWallet("");
        setOwnerWalletError(false);
        setPrinterWallet("");
        setPrinterWalletError(false);
        setCoaHash("");
        setCoaHashError(false);
        if (isValidCollectionAddress) {
            let checksumAddress = ethers.utils.getAddress(value);
            setCollectionAddress(checksumAddress);
            let newNFTContract = new ethers.Contract(checksumAddress, ERC721_ABI, NFT_PROVIDER);
            setNftContract(newNFTContract);
            try {
                let nftContractName = await newNFTContract.name();
                setCollectionName(nftContractName);
                setCollectionNameError(false);
            } catch (e) {
                alert("There was a problem retrieving the collection name.");
            }
        }
    }

    function onOwnerWalletAddressChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        let value = event.target.value;
        let isValidAddress = ethers.utils.isAddress(value);
        setOwnerWalletError(!isValidAddress);
        setOwnerWallet(value);
        setPreviewCOA(null);
        if (isValidAddress) {
            let checksumAddress = ethers.utils.getAddress(value);
            setOwnerWallet(checksumAddress);
        }
    }

    function onPrinterWalletAddressChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        let value = event.target.value;
        let isValidAddress = ethers.utils.isAddress(value);
        setPrinterWalletError(!isValidAddress);
        setPrinterWallet(value);
        setPreviewCOA(null);
        if (isValidAddress) {
            let checksumAddress = ethers.utils.getAddress(value);
            setPrinterWallet(checksumAddress);
        }
    }

    function onPrinterTimestampChange(event: any) {
        setPrintTimestamp(event);
    }

    function onPrintSiteChange(event: React.SyntheticEvent, newPrintSite: SelectOption | string | null) {
        setPreviewCOA(null);
        setPrintSite(newPrintSite);
        setPrintSiteError(newPrintSite === null);
    }

    function onPrintMaterialChange(event: React.SyntheticEvent, newPrintMaterial: SelectOption | string | null) {
        setPreviewCOA(null);
        setPrintMaterial(newPrintMaterial);
        setPrintMaterialError(newPrintMaterial === null);
    }

    function onPrintSizeChange(event: React.SyntheticEvent, newPrintSize: SelectOption | string | null) {
        setPreviewCOA(null);
        setPrintSize(newPrintSize);
        setPrintSizeError(newPrintSize === null);
    }

    function onExtraNotesChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        let value = event.target.value;
        setExtraNotes(value);
        setPreviewCOA(null);
    }

    function onImageURIChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        let value = event.target.value;
        setImageUri(value);
        setPreviewCOA(null);
    }

    function onCOAHashChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        let value = event.target.value;
        setCoaHash(value);
        if (value !== "" && value.length > 0) {
            setCoaHashError(false);
        } else {
            setCoaHashError(true);
        }
    }

    function resetCreateCOAForm() {
        setCollectionAddress("");
        setCollectionAddressError(false);
        setCollectionName("");
        setTokenID("");
        setImageUri("");
        setImageUriError(false);
        setOwnerWallet("");
        setOwnerWalletError(false);
        setPrinterWallet("");
        setPrinterWalletError(false);
        setPrintSize(createPageData.printSizes[0]);
        setPrintSite(createPageData.printSites[0]);
        setExtraNotes("");
        setCoaHash("");
        setNftContract(null);
        setPreviewCOA(null);
        setCoaRegisterTxHash(null);
        setIsFormValid(false);
        return;
    }

    const handleTokenIDChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setTokenID(event.target.value);
        setPreviewCOA(null);
        if (event.target.value.length > 0) {
            setTokenIdError(false);
        } else {
            setTokenIdError(true);
        }
    };

    async function fillTokenIDInfo() {
        if (nftContract !== null && tokenID !== "") {
            try {
                let tokenOwnerAddress = await nftContract.ownerOf(tokenID);

                let isValidAddress = ethers.utils.isAddress(tokenOwnerAddress);
                if (isValidAddress) {
                    let checksumAddress = ethers.utils.getAddress(tokenOwnerAddress);
                    setOwnerWallet(checksumAddress);
                    setOwnerWalletError(false);
                    setPrinterWallet(checksumAddress);
                    setPrinterWalletError(false);
                    let coaHash: string = generateCOAHash(collectionAddress, tokenID, checksumAddress);
                    setCoaHash(coaHash);
                }
            } catch (e) {
                console.error("Problem getting token ownerOf.");
                console.error(e);
            }

            try {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Basic MDdmMGRlOTMyY2M3NGY4MzlkMzk4MGI3ZjA1NmI3MzI6Y2ZjZTQ5YTE4ZWZmNDJjZDlkYzY5MDI5NzNhMTIzYTI=");

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                console.log("Trying to get from nft infura");
                // @ts-ignore
                await fetch(`https://nft.api.infura.io/networks/1/nfts/${collectionAddress}/tokens/${tokenID}?resyncMetadata=false`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log(result)
                        setImageUri(result.metadata.image)
                    })
                    .catch(error => console.log('error', error));
            } catch (e) {
                console.error("Problem getting token Image URI.");
                console.error(e);
            }
        }
    }

    async function createNewCOA() {
        if (previewCOA !== null) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "collectionAddress": collectionAddress,
                    "image": imageURI,
                    "tokenId": parseInt(tokenID),
                    "printSize": typeof printSize === "object" ? printSize!.label : printSize,
                    "printMaterial": typeof printMaterial === "object" ? printMaterial!.label : printMaterial,
                    "ownerWallet": ownerWallet,
                    "printerWallet": printerWallet,
                    "timestamp": printTimestamp.unix(),
                    "source": typeof printSite === "object" ? printSite!.label : printSite,
                    "hash": coaHash
                })
            };
            console.debug(requestOptions);
            const registerCoaResponse = await fetch(`${process.env.REACT_APP_TOOLS_API_HOST}/coas`, requestOptions);
            console.debug(registerCoaResponse);
            const responseJSON = await registerCoaResponse.json();
            if (responseJSON.status == "success") {
                setCoaRegisterTxHash(responseJSON.url);
            }
            else {
                alert(`Error creating coa: ${responseJSON.error}`);
            }
        }
    }

    const handleClickTokenIDFetch = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (tokenID !== "" && tokenID.length > 0 && nftContract !== null) {
            fillTokenIDInfo();
        } else {
            alert("You must enter a valid Collection Address and Token ID first.")
        }
    };

    const handleMouseDownTokenID = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    function validateAndPreview() {
        if (collectionAddress === "") {
            setCollectionAddressError(true);
            setIsFormValid(false);
        }
        if (collectionName === "") {
            setCollectionNameError(true);
            setIsFormValid(false);
        }
        if (tokenID === "") {
            setTokenIdError(true);
            setIsFormValid(false);
        }
        if (ownerWallet === "") {
            setOwnerWalletError(true);
            setIsFormValid(false);
        }
        if (printerWallet === "") {
            setPrinterWalletError(true);
            setIsFormValid(false);
        }
        if (coaHash === "") {
            setCoaHashError(true);
            setIsFormValid(false);
        }
        if (imageURI === "") {
            setImageUriError(true);
            setIsFormValid(false);
        }
        if (
            (collectionAddress !== "" && !collectionAddressError) &&
            (collectionName !== "" && !collectionNameError) &&
            (tokenID !== "" && !tokenIdError) &&
            (ownerWallet !== "" && !ownerWalletError) &&
            (printerWallet !== "" && !printerWalletError) &&
            (imageURI !== "" && !imageURIError) &&
            (coaHash !== "" && !coaHashError)
        ) {
            setIsFormValid(true);
            setPreviewCOA({
                collectionAddress: collectionAddress,
                collectionName: collectionName,
                tokenID: parseInt(tokenID),
                imageURI: imageURI,
                printSize: typeof printSize === "object" ? printSize!.label : printSize,
                printMaterial: typeof printMaterial === "object" ? printMaterial!.label : printMaterial,
                ownerWallet: ownerWallet,
                printerWallet: printerWallet,
                printTimestamp: printTimestamp.format("lll"),
                printSite: typeof printSite === "object" ? printSite!.label : printSite,
                extraNotes: extraNotes,
                coaHash: coaHash,
                authentic: true,
            });
        } else {
            console.error("Current form failed validation, not setting preview COA.");
        }
    }

    let cardContents = null;

    if (!isActive) {
        cardContents = (<ConnectButton/>);
    } else if (isActive && !CREATOR_WALLET_ADDRESSES.includes(account!)) {
        cardContents = <ErrorAlert alert_message={"Your wallet is not authorized."}/>;
    } else if (isActive && CREATOR_WALLET_ADDRESSES.includes(account!)) {
        // @ts-ignore
        cardContents = (
            <Box component={"form"} autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"}>Create new COA.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField required id="collection-address" label="Collection Address" fullWidth={true}
                                   error={collectionAddressError} value={collectionAddress} onChange={async (event) => {
                            await onCollectionAddressChange(event);
                        }}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField required id="collection-name" label="Collection Name" fullWidth={true}
                                   value={collectionName} error={collectionNameError}
                                   InputProps={{
                                       readOnly: true,
                                   }}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="token-id">Token ID</InputLabel>
                            <OutlinedInput
                                id="token-id"
                                type={"text"}
                                label={"Token ID"}
                                value={tokenID}
                                onChange={handleTokenIDChange}
                                error={tokenIdError}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Pull token info."
                                            onClick={handleClickTokenIDFetch}
                                            onMouseDown={handleMouseDownTokenID}
                                            edge="end"
                                        >
                                            <SearchIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {/*{imageURI.length > 0 ? <img alt={"Image src checker."} style={{display: "none"}} src={imageURI} onError={() => {*/}
                        {/*    setImageUriError(true);*/}
                        {/*}} onLoad={() => {*/}
                        {/*    setImageUriError(false);*/}
                        {/*}}/> : null}*/}
                        <TextField required id="image-uri" label="Image URI" fullWidth={true} onChange={onImageURIChange} value={imageURI}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            disablePortal
                            id="print-size"
                            options={createPageData.printSizes}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={printSize}
                            onChange={onPrintSizeChange}
                            freeSolo={true}
                            renderInput={(params) => <TextField {...params} label="Print Size" error={printSizeError}/>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            disablePortal
                            id="print-material"
                            options={createPageData.printMaterials}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={printMaterial}
                            onChange={onPrintMaterialChange}
                            freeSolo={true}
                            renderInput={(params) => <TextField {...params} label="Print Material" error={printMaterialError}/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField required id="owner-wallet" label="Owner Wallet" fullWidth={true}
                                   error={ownerWalletError} value={ownerWallet} onChange={onOwnerWalletAddressChange}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField required id="printer-wallet" label="Printer Wallet" fullWidth={true}
                                   error={printerWalletError} value={printerWallet}
                                   onChange={onPrinterWalletAddressChange}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl id={"print-timestamp"} fullWidth>
                            <DateTimePicker label="Print Timestamp" value={printTimestamp}
                                            onChange={onPrinterTimestampChange}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            disablePortal
                            id="print-site"
                            options={createPageData.printSites}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={printSite}
                            onChange={onPrintSiteChange}
                            freeSolo={true}
                            renderInput={(params) => <TextField {...params} label="Print Site" error={printSiteError}/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField id="extra-notes" label="Extra Notes" fullWidth={true} value={extraNotes}
                                   onChange={onExtraNotesChange}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField required id="coa-hash" label="COA Hash" fullWidth={true}
                                   value={coaHash} error={collectionNameError}
                                   InputProps={{
                                       readOnly: true,
                                   }}/>
                    </Grid>
                    {coaRegisterTXHash !== null ?
                        <>
                            {/*<Grid item xs={6}>*/}
                            {/*    <Link href={`https://etherscan.io/tx/${coaRegisterTXHash}`} target={"_blank"}>*/}
                            {/*        View COA Registration TX On Etherscan.*/}
                            {/*    </Link>*/}
                            {/*</Grid>*/}
                            <Grid item xs={6}>
                                <Link href={`/#/auth/${coaHash}`} target={"_blank"}>
                                    View this COA.
                                </Link>
                            </Grid>
                        </> : null}
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={resetCreateCOAForm}>Reset</Button>
                            <Button variant="contained" onClick={validateAndPreview}>Validate & Preview</Button>
                            <Button variant="contained" disabled={!isFormValid || previewCOA === null}
                                    onClick={async () => {
                                        await createNewCOA();
                                    }}>Create</Button>
                            {/* todo dont need separate register button if just fire tx after in other click */}
                            {/*<Button variant="contained" disabled={previewCOA === null || coaRegisterTXHash === null} onClick={async () => { await createNewCOA(); }}>Register TX</Button>*/}
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={previewCOA !== null ? 9 : 12}>
                <Card>
                    <CardContent>
                        {cardContents}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={previewCOA !== null ? 3 : 12}>
                {isFormValid && previewCOA !== null ? <COACard coa={previewCOA}/> : null}
            </Grid>
        </Grid>
    );
}