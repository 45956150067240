import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import * as React from "react";

export function ErrorAlert({alert_message}: { alert_message: string }) {
    return (<Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {alert_message}
        </Alert>
    );
}