import {useWeb3React} from "@web3-react/core";
import {WalletButton} from "./wallet-button";

export default function ConnectButton() {
    const {connector} = useWeb3React();

    return (
        <WalletButton variant="contained" onClick={async () => {
            await connector.activate();
        }}>Connect Wallet</WalletButton>
    );
}