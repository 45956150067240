export default function COAImage({image_uri}: { image_uri: string }) {
    image_uri = image_uri.replace(/\\(.)/mg, "$1");
    console.debug(image_uri);
    if (image_uri.includes("ipfs://")) {
        image_uri = image_uri.replace("ipfs://", "https://cf-ipfs.com/ipfs/");
    }
    else if (image_uri.includes("https://ipfs.io")) {
        image_uri = image_uri.replace("https://ipfs.io", "https://cf-ipfs.com");
    }

    return (
        <img alt={"NFT Image Preview" } src={image_uri} style={{width: "100%"}}/>
    );
}