import {styled} from "@mui/material/styles";
import {Button, ButtonProps} from "@mui/material";
import {blue} from "@mui/material/colors";

export const WalletButton = styled(Button)<ButtonProps>(({theme}) => ({
    color: theme.palette.getContrastText(blue[400]),
    backgroundColor: blue[400],
    '&:hover': {
        backgroundColor: blue[600],
    },
}));